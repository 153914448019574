import React from 'react';
import PropTypes from 'prop-types';
import { Bars as MenuIcon } from '@styled-icons/fa-solid/Bars';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { rotateMixin } from '../lib/constants/animations';
import theme from '../lib/theme';
import { Link } from '../server/pages';

import { Box, Flex } from './Grid';
import Hide from './Hide';
import SearchForm from './SearchForm';
import SearchIcon from './SearchIcon';
import TopBarProfileMenu from './TopBarProfileMenu';
import { withUser } from './UserProvider';

const Logo = styled.img.attrs({
  src: '/static/images/innova-icon.svg',
  alt: 'Donate PR logo',
})`
  ${({ animate }) => (animate ? rotateMixin : null)};
`;

const SearchFormContainer = styled(Box)`
  max-width: 30rem;
  min-width: 10rem;
`;

const NavList = styled(Flex)`
  list-style: none;
  min-width: 20rem;
  text-align: right;
  align-items: center;
`;

const NavLinkContainer = styled(Box)`
  text-align: center;
`;
NavLinkContainer.defaultProps = {
  as: 'li',
  px: [1, 2, 3],
};

const NavLink = styled.a`
  color: #777777;
  font-size: 1.4rem;
`;

class TopBar extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    loadingLoggedInUser: PropTypes.bool,
    showSearch: PropTypes.bool,
    menuItems: PropTypes.object,
  };

  static defaultProps = {
    className: '',
    showSearch: true,
    menuItems: {
      discover: true,
      docs: true,
      howItWorks: false,
      pricing: false,
      leaderboard: true,
    },
  };

  render() {
    const { showSearch, menuItems } = this.props;
    const defaultMenu = { discover: true, docs: true, howItWorks: false, pricing: false, leaderboard: true };
    const merged = { ...defaultMenu, ...menuItems };
    return (
      <Flex
        px={3}
        py={showSearch ? 2 : 3}
        alignItems="center"
        flexDirection="row"
        justifyContent="space-around"
        css={{
          height: theme.sizes.navbarHeight,
          background: 'white',
          boxShadow: '14px 14px 40px 0 rgba(118,126,173,.08)',
        }}
      >
        <Link route="home" passHref>
          <Flex as="a" alignItems="center">
            <Logo width="24" height="24" />
            {/* <Hide xs> */}
            <Box mx={2}>
              <img style={{ maxWidth: 125 }} height="16px" src="/static/images/logotype.svg" alt="Donate PR" />
            </Box>
            {/* </Hide> */}
          </Flex>
        </Link>

        {showSearch && (
          <Flex justifyContent="center" flex="1 1 auto">
            <Hide xs width={1}>
              <SearchFormContainer p={2}>
                <SearchForm />
              </SearchFormContainer>
            </Hide>
          </Flex>
        )}

        <Flex alignItems="center" justifyContent="flex-end" flex="1 1 auto">
          <Hide sm md lg>
            <Box mx={3}>
              <Link href="/search">
                <Flex as="a">
                  <SearchIcon fill="#aaaaaa" size={24} />
                </Flex>
              </Link>
            </Box>
          </Hide>

          <Hide sm md lg>
            <Box mx={3}>
              <Link href="#footer">
                <Flex as="a">
                  <MenuIcon color="#aaaaaa" size={24} />
                </Flex>
              </Link>
            </Box>
          </Hide>

          <Hide xs>
            <NavList as="ul" p={0} m={0} justifyContent="space-around" css="margin: 0;">
              {merged.discover && (
                <NavLinkContainer
                  style={
                    {
                      // padding: '7px 27px',
                    }
                  }
                >
                  <Link route="discover" passHref>
                    <NavLink
                      style={{
                        // color: '#ec7d1d',
                        border: '1px solid #eb7400',
                        fontWeight: 700,
                        padding: '9px 27px',
                        color: '#f0a367',
                      }}
                    >
                      <FormattedMessage id="menu.discover" defaultMessage="Discover" />
                    </NavLink>
                  </Link>
                </NavLinkContainer>
              )}
              {merged.howItWorks && (
                <NavLinkContainer>
                  <Link route="marketing" params={{ pageSlug: 'how-it-works' }} passHref>
                    <NavLink>
                      <FormattedMessage id="menu.howItWorks" defaultMessage="How it Works" />
                    </NavLink>
                  </Link>
                </NavLinkContainer>
              )}
              {merged.pricing && (
                <NavLinkContainer>
                  <Link route="pricing" passHref>
                    <NavLink>
                      <FormattedMessage id="menu.pricing" defaultMessage="Pricing" />
                    </NavLink>
                  </Link>
                </NavLinkContainer>
              )}
              {merged.docs && (
                <NavLinkContainer>
                  <NavLink href="https://docs.donatepr.com">
                    <FormattedMessage id="menu.docs" defaultMessage="Docs & Help" />
                  </NavLink>
                </NavLinkContainer>
              )}

              {merged.leaderboard && (
                <NavLinkContainer>
                  <Link href="/campaign/donatepr-year-end-charity-challege-2023">
                    <NavLink style={{ display: 'flex', alignItems: 'center' }}>
                      <img
                        style={{ width: '50px', height: 'auto', marginRight: 5 }}
                        src="/static/images/partner/hipr-transpr-ct.png"
                      />
                      <FormattedMessage id="menu.leaderboard" defaultMessage="Leaderboard" />
                    </NavLink>
                  </Link>
                </NavLinkContainer>
              )}
            </NavList>
          </Hide>

          <TopBarProfileMenu />
        </Flex>
      </Flex>
    );
  }
}

export default withUser(TopBar);
